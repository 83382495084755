<template>
    <div class="mainblock">
        <div class="mainblockheader">
            <div class="mainblockheadertext">
                Building blocks
            </div>
            <div class="mainblockheadersubtext">
                We categorize our domain collection to assist
                you in finding the one that best suits your business field.
            </div>
        </div>
        <v-row  class="mainblocksgrid" >
            <div v-for="(mainBlock,index) in displayedItemsNew" :key="index" class="mainblockbox"  v-bind:style="{background: mainBlock.color}"  >
                <a :href="`/blocks?`+mainBlock.priority" :aria-label="mainBlock.description">

                    <div class="mainblockbox-2"
                         v-bind:style="{ 'background-image': 'url('+ mainBlock.imageName + ')' }" >
                        <div class="mainblockdesc">{{mainBlock.description.charAt(0)+mainBlock.description.slice(1).toLowerCase()}}</div>
<!--                        <a :href="`/blocks?`+mainBlock.id" :aria-label="mainBlock.description">-->
                            <div class="mainblockarrow"><v-icon icon="mdi">
                            </v-icon><img class="mainblockarrowicon" src="./../assets/images/arrow.svg" alt="arrow">
                            </div>

                    </div>
                </a>
                    <a class="blocksLink-mob" :href="`/blocks?`+mainBlock.priority" :aria-label="mainBlock.description">
                        <div class="mainblockbox-2-mob"
                            v-bind:style="{ 'background-image': 'url('+ mainBlock.imageNameMobile + ')' }" >
                        <div class="mainblockdesc"exploreDomainsSubtext>{{mainBlock.description.charAt(0)+mainBlock.description.slice(1).toLowerCase()}}</div>
<!--                        <a :href="`/blocks?`+mainBlock.id"><div class="mainblockarrow"><v-icon icon="mdi"></v-icon><img class="mainblockarrowicon" src="./../assets/images/arrow.svg" ></div></a>-->
                        </div>
                    </a>
<!--                </a>-->
            </div>
        </v-row>


    </div>

</template>

<script>
    import {eventBus} from "@/main";


    import auto from './../assets/images/blocks/auto_grid.svg'
    import individuals from '../assets/images/blocks/individuals_grid.svg';
    import brands from './../assets/images/blocks/brands_grid.svg';
    import business from './../assets/images/blocks/business_grid.svg';
    import cakes from './../assets/images/blocks/cakes_grid.svg';
    import climate from './../assets/images/blocks/climate_grid.svg';
    import cultural from './../assets/images/blocks/cultural_grid.svg';
    import energy from './../assets/images/blocks/energy_grid.svg';
    import food from './../assets/images/blocks/food_grid.svg';
    import geo from './../assets/images/blocks/geography_grid.svg';
    import nomads from './../assets/images/blocks/nomads_grid.svg';
    import ship from './../assets/images/blocks/ship_grid.svg';
    import society from './../assets/images/blocks/society_grid.svg';
    import sports from './../assets/images/blocks/sports_grid.svg';
    import digital from './../assets/images/blocks/digital_grid.svg';

    import automob from './../assets/images/blocks/auto_grid_mob.svg'
    import individualsmob from './../assets/images/blocks/individuals_grid_mob.svg';
    import brandsmob from './../assets/images/blocks/brands_grid_mob.svg';
    import businessmob from './../assets/images/blocks/business_grid_mob.svg';
    import cakesmob from './../assets/images/blocks/cakes_grid_mob.svg';
    import climatemob from './../assets/images/blocks/climate_grid_mob.svg';
    import culturalmob from './../assets/images/blocks/cultural_grid_mob.svg';
    import energymob from './../assets/images/blocks/energy_grid_mob.svg';
    import foodmob from './../assets/images/blocks/food_grid_mob.svg';
    import geomob from './../assets/images/blocks/geography_grid_mob.svg';
    import nomadsmob from './../assets/images/blocks/nomads_grid_mob.svg';
    import shipmob from './../assets/images/blocks/ship_grid_mob.svg';
    import societymob from './../assets/images/blocks/society_mob.svg';
    import sportsmob from './../assets/images/blocks/sports_grid_mob.svg';
    import digitalmob from './../assets/images/blocks/digital_grid_mob.svg';


    import mega from './../assets/images/mega.jpg';
    // import climate from './../assets/images/climate.jpg';

    import econ from './../assets/images/econ.jpg';
    import enrg from './../assets/images/enrg.jpg';

    import bizco from './../assets/images/megaMob.jpg';
    import megamob from './../assets/images/megaMob.jpg';
    import bizcomob from './../assets/images/bizcoMob.jpg';
    import econmob from './../assets/images/econMob.jpg';
    import enrgmob from './../assets/images/enrgMob.jpg';
    import imagehouset from './../assets/images/house.jpg';
    import imagefaltt from './../assets/images/flat.jpg';
    import skull from '@/assets/images/skull.png';

    import request from "@/requests";
    import DeleteDialog from "@/components/reusable/DeleteDialog";

    const customStyles = {
        ".v-input__control": {
            border: "1px solid #3E82F1",
        },
        ".v-icon": {
            color: "#3E82F1",
        },
        ".v-select__selection--comma": {
            color: "#3E82F1",
        },
    };

    export default {
        name: "DomainsGrid",
        components: {DeleteDialog},

        data: () => ({
                itemsPerPage: 24,
                visiblePageCount: 5,
                currentPage: 1,
                imagenamelist:[digital,auto,individuals,brands,business,cakes,climate,cultural,energy,food,nomads,ship,geo,society,sports],
                imagenamelistmob:[digitalmob,automob,individualsmob,brandsmob,businessmob,cakesmob,climatemob,culturalmob,energymob,foodmob,nomadsmob,shipmob,geomob,societymob,sportsmob],
                image:bizco,
                imagemob:megamob,
                imagehouse:imagehouset,
                imageflat:imagefaltt,
                skullimage: skull,
                backgroundcolor:'#ee4242',

                title:"ENERG",
                description:"Refers to domain names that cover the Oil and Energy industry.",
                mainBlock:{id:"",
                    description:"",
                    imageName:"",
                    imageNameMobile:"",
                },
                displayedItemsNew:[],

                blocks:{
                    id:"",
                    description:"",
                    imageName:"",
                    imageNameMobile:"",
                },
                defaultSelected: {
                    value: "domainId",
                    parameters: "mono"
                },
                domainInfo: [{
                    value: "1",
                    parameters: "monoverse"
                },{
                    value: "2",
                    parameters: "hackverse"
                },{
                    value: "3",
                    parameters: "babuverse"
                }],
                selectedFilter: {
                    id: 1,
                    description : 'Premium'
                },
                domainFilters: [
                    {
                        id: 1,
                        description : 'Premium'
                    },
                    {
                        id: 2,
                        description : 'Offer'
                    },
                    {
                        id: 3,
                        description : 'Popularity'
                    },
                    {
                        id: 4,
                        description : 'Lowest Price'
                    },
                    {
                        id: 5,
                        description : 'Highest Price'
                    },
                    {
                        id: 6,
                        description : 'Shortest Length'
                    },
                    {
                        id: 7,
                        description : 'Longest Length'
                    },
                ]


            }
        ),

        methods: {

            previousPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                }
            },
            goToPage(pageNumber) {
                if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                    this.currentPage = pageNumber;
                }
            },

            getBgImagePath(domain){
                if(domain.flat){
                    return '@/assets/images/back.png'
                }else{
                    return '@/assets/images/header_img.png'
                }
            },


            getMainBlock() {

                this.$store.commit('setLoadingModal', true);
                request.getRequest('', 'api/main-block/', '', this.getMainBlockCallBack);

            },
            getDomains() {

                let path = this.getParams().toUpperCase();
                if (path.length<2){
                    // console.log(path)
                    path = 'ECON'
                }
                let imagePath = '@/assets/images/'+path.toLowerCase()+'.jpg'
                // console.log(path);
                // this.$store.commit('setLoadingModal', true);
                request.getRequest('', 'api/domains-public', '', this.getDomainsCallBack);


            },

            // getBlocksBgMob(blocks){
            //     let i = 0;
            //     this.imagemob='';
            //     for (i=0; i<this.imagenamelistmob.length; i++){
            //         // console.log(this.imagenamelist[0])
            //         if(this.imagenamelistmob[i].includes(blocks.description.toLowerCase())){
            //             this.imagemob=this.imagenamelistmob[i]
            //             // console.log(this.imagenamelist[i])
            //             // this.imagemob=this.imagenamelistmob[i]
            //         }
            //     }
            //     return this.imagemob
            // },


            getBlocksBg(blocks){


                let i = 0;
                this.image='';
                this.imagemob='';
                for (i=0; i<this.imagenamelist.length; i++){
                        // console.log(this.imagenamelist[0])
                        if(this.imagenamelist[i].includes(blocks.description.toLowerCase())){
                            this.image=this.imagenamelist[i]
                            this.imagemob=this.imagenamelistmob[i]
                            // console.log(this.imagenamelist[i])
                            // this.imagemob=this.imagenamelistmob[i]
                        }
                    }
                return this.image


            },

            getMainBlockCallBack(response) {
                this.$store.commit('setMainBlocks', response);
                this.$store.commit('setLoadingModal', false);
            },
            getDomainsCallBack(response) {
                this.$store.commit('setDomainsInfo', response);
            },

            getBlock(){
                request.getRequest('', 'api/main-block', '', this.getBlockCallBack);


            },

            getBlockCallBack(response) {


                let obj={}

                obj=response;

                let i =0;
                let j =0;
                for (i=0; i<obj.length; i++){

                    for (j=0; j<this.imagenamelist.length;j++){

                        if(this.imagenamelist[j].includes(obj[i].description.toLowerCase())){
                            obj[i].imageName = this.imagenamelist[j];
                            obj[i].imageNameMobile = this.imagenamelistmob[j];

                             }

                        }

                    }


                this.$store.commit('setMainBlocks', obj);
                this.displayedItemsNew = this.$store.getters.mainBlocks

            },

            getParams(){
                this.defaultSelected.parameters = this.$route.fullPath
                return this.defaultSelected.parameters
            },


            updateImageName(){

            },

            changeFilter(filter){
                if(filter.id === 1){

                }
                else if(filter.id === 2){

                  this.$store.getters.domainInfo.filter(item => !!item.offer)
                }
                else if(filter.id === 3){

                  this.$store.getters.domainInfo.filter(item => !!item.popular)
                }
                // lowest price
                else if(filter.id === 4){
                    this.$store.getters.domainInfo.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
                }
                // highest price
                else if(filter.id === 5){
                    this.$store.getters.domainInfo.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)).reverse();
                }
                else if(filter.id === 6){
                    this.$store.getters.domainInfo.sort((a, b) => a.description.length - b.description.length);
                }
                else if(filter.id === 7){
                    this.$store.getters.domainInfo.sort((a, b) => a.description.length - b.description.length).reverse();
                }
            }


        },




        computed: {

            // WE MAY NEED THIS AGAIN
            // displayedItems() {
            //     // const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            //     // const endIndex = startIndex + this.itemsPerPage;
            //     // console.log(this.$store.getters.mainBlocks)
            //
            //     return this.$store.getters.mainBlocks;
            // },


            totalPages() {
                return Math.ceil(this.$store.getters.mainBlocks.length / this.itemsPerPage);
            },
            visiblePageNumbers() {
                const middlePage = Math.ceil(this.visiblePageCount / 2);
                const startPage = Math.max(this.currentPage - middlePage, 1);
                const endPage = Math.min(startPage + this.visiblePageCount - 1, this.totalPages);

                const pageNumbers = [];
                for (let i = startPage; i <= endPage; i++) {
                    pageNumbers.push(i);
                }
                return pageNumbers;
            },


        },

        watch: {

        },



        created() {

            // this.computed.displayedItems()

            this.getBlock()
            this.getDomains()
            this.getParams()
            this.updateImageName()




            // console.log(this.parameter)
            // console.log(1)
            // return this.$route.fullPath.split("&")[1].split("=")[1]
            // this.$router.replace({ name: "/join", params: {id:"123"}, query: {q1: "q1"} })
            // console.log(this.$router.currentRoute.query.q1)
            // this.getMainBlock();
            //
            // eventBus.$on('MainBlockDelete', (() => {
            //     this.deleteItem();
            // }));
        },
        // beforeDestroy() {
        //     // eventBus.$off('MainBlockDelete');
        // }
    }
    // <!-- Google tag (gtag.js) -->
    // window.dataLayer = window.dataLayer || [];
    // function gtag(){dataLayer.push(arguments);}
    // gtag('js', new Date()); gtag('config', 'G-D08V2FNZW0');


</script>
