<template>
    <div>
        <div>
            <headerComponent>
            </headerComponent>
        </div>
        <div class="headerbg" id="gradient">
        <div class="headerImage" >
<!--            <animate></animate>-->
            <div class="headerGrid">
                <div class="headerGridRow_1">
                    <p class="subtextheader">Welcome to Monoverse</p>
<!--                    <img src="./../assets/images/logo3.svg">-->
                </div>
                <div class="headerGridRow_2"><h1 class="welcome">The Verse of Domain Names</h1></div>
                <div class="headerGridRow_3"><p class="subtextheader">Trade your business with a premium .com domain ending with Verse, Cakes, Nomads and more.
                    Become a Monoversian to use Monoverse Trademark and Branding.</p></div>
                <div class="headerGridRow_4">
                    <a href="/domains"><div class="headerJoinBtnMain" ><p class="headerMoreBtntext">Explore our domain portfolio</p></div></a>
<!--                    <a href="/join"><div class="headerMoreBtnMain" ><p class="headerJoinBtntext">Discover more</p></div></a>-->
                </div>
            </div>
        </div>
        </div>
        <div>
            <mainBlocksGrid></mainBlocksGrid>
        </div>
        <div class="domainsbg">

<!--            <explore></explore>-->
            <div>
                <div class="exploreDomains">
                    <h2>Explore our domain collection</h2>
                </div>
                <div class="exploreDomainsSubtext">
                    <p>Select a domain, become a Monoversian and start your business using Monoverse’s branding and digital assets.</p>
                </div>

            </div>
            <DomainsGrid></DomainsGrid>

            <div>
                <div class="exploreBtn">
                    <a href="/domains"><div class="headerJoinBtnMain" style="width: 177px; height: 48px;margin-bottom: 128px;margin-top: 32px" ><p class="headerMoreBtntext">Explore More</p></div></a>
                    <!--                    <a href="/join"><div class="headerMoreBtnMain" ><p class="headerJoinBtntext">Discover more</p></div></a>-->
                </div>
            </div>
        </div>
<!--        <div class="faq" >-->
<!--            <faq></faq>-->
<!--        </div>-->
        <div>
            <mainfooter></mainfooter>
        </div>

    </div>


</template>


<script>
    import {eventBus} from "@/main";

    import mega from './../assets/images/mega.jpg';
    import climate from './../assets/images/climate.jpg';
    import bizco from './../assets/images/bizco.jpg';
    import econ from './../assets/images/econ.jpg';
    import enrg from './../assets/images/enrg.jpg';

    import megamob from './../assets/images/megaMob.jpg';
    import climatemob from './../assets/images/climateMob.jpg';
    import bizcomob from './../assets/images/bizcoMob.jpg';
    import econmob from './../assets/images/econMob.jpg';
    import enrgmob from './../assets/images/enrgMob.jpg';
    import imagehouset from './../assets/images/house.jpg';
    import imagefaltt from './../assets/images/flat.jpg';
    import skull from '@/assets/images/skull.png';

    import request from "@/requests";
    import DeleteDialog from "@/components/reusable/DeleteDialog";
    import DomainsGrid from "@/components/reusable/DomainsGrid";
    import explore from "@/components/reusable/exploreGrid";
    import headerComponent from "@/components/reusable/header";
    // import animate from "@/components/reusable/anime";
    import faq from "@/components/reusable/faq";
    import mainBlocksGrid from "@/components/mainBlocksGrid";
    import mainfooter from "@/components/footer";

    export default {
        name: "MainBlock",
        components: {DeleteDialog,DomainsGrid,explore,headerComponent,mainBlocksGrid,mainfooter,faq},

        data: () => ({
                imagenamelist:[mega,climate,bizco,econ,enrg],
                imagenamelistmob:[megamob,climatemob,bizcomob,econmob,enrgmob],
                image:mega,
                imagemob:megamob,
                imagehouse:imagehouset,
                imageflat:imagefaltt,
                skullimage: skull,

                title:"ENERG",
                description:"Refers to domain names that cover the Oil and Energy industry.",
                mainBlock:{id:"",
                    description:""
                },
                defaultSelected: {
                    value: "domainId",
                    parameters: "mono"
                },
                domainInfo: [{
                    value: "1",
                    parameters: "monoverse"
                },{
                    value: "2",
                    parameters: "hackverse"
                },{
                    value: "3",
                    parameters: "babuverse"
                }],


            }
        ),

        methods: {

            getBgImagePath(domain){
                if(domain.flat){
                    return '@/assets/images/back.png'
                }else{
                    return '@/assets/images/header_img.png'
                }
            },


            getMainBlock() {

                this.$store.commit('setLoadingModal', true);
                request.getRequest('', 'api/main-block', '', this.getMainBlockCallBack);

            },

            getMainBlockCallBack(response) {

                this.$store.commit('setMainBlocks', response);
                this.$store.commit('setLoadingModal', false);
            },
            getDomains() {

                let path = this.getParams().toUpperCase();
                if (path.length<2){
                    // console.log(path)
                    path = 'ENRG'
                }
                let imagePath = '@/assets/images/'+path.toLowerCase()+'.jpg'
                // console.log(path);
                // this.$store.commit('setLoadingModal', true);
                request.getRequest('', 'api/by-block/' +path, '', this.getDomainsCallBack);

            },

            getDomainsCallBack(response) {

                this.$store.commit('setDomainsInfo', response);
                // console.log(response)
                // this.$store.commit('setLoadingModal', false);
            },

            getBlock(){
                request.getRequest('', 'api/main-block', '', this.getBlockCallBack);
            },

            getBlockCallBack(response) {
                this.$store.commit('setMainBlocks', response);
                this.mainBlock=response;

                // console.log(this.$store.getters.mainBlocks)


                let i = 0;
                for (i=0; i<this.mainBlock.length; i++){
                    // console.log(this.imagenamelist[0])
                    if (this.mainBlock[i].description.toLowerCase() === this.defaultSelected.parameters.split("/")[1].toLowerCase()){
                        // console.log(this.mainBlock[i].description)
                        this.title=this.mainBlock[i].description
                        this.description = this.mainBlock[i].body

                        // console.log(this.defaultSelected.parameters)
                    }else{
                        // console.log("ENERG")
                        // console.log(this.mainBlock[i].description)
                        // console.log(this.defaultSelected.parameters.split("/")[1])
                    }

                }
                // console.log(response)
                // this.$store.commit('setDomainsInfo', response);
                // console.log(response)
                // this.$store.commit('setLoadingModal', false);
            },

            getParams(){
                // console.log(this.$route.fullPath.split("&")[1].split("=")[1])
                this.defaultSelected.parameters = this.$route.fullPath
                // this.mainBlocks.description=mainBlocks.description
                // console.log(this.$store.mainBlocks.description)
                // console.log(this.defaultSelected.parameters.toLowerCase())
                // for image in imagelist
                let i = 0;
                for (i=0; i<this.imagenamelist.length; i++){
                    // console.log(this.imagenamelist[0])
                    if(this.imagenamelist[i].includes(this.defaultSelected.parameters.toLowerCase())){
                        this.image=this.imagenamelist[i]
                        this.imagemob=this.imagenamelistmob[i]
                    }
                }
                return this.defaultSelected.parameters
            }

        },


        computed: {

            blocks() {

                return this.$store.getters.mainBlocks;
            },

        },

        watch: {

        },



        created() {
            this.getBlock()
            this.getDomains()
            this.getParams()
            // this.getBlock()

            // console.log(this.parameter)
            // console.log(1)
            // return this.$route.fullPath.split("&")[1].split("=")[1]
            // this.$router.replace({ name: "/join", params: {id:"123"}, query: {q1: "q1"} })
            // console.log(this.$router.currentRoute.query.q1)
            // this.getMainBlock();
            //
            // eventBus.$on('MainBlockDelete', (() => {
            //     this.deleteItem();
            // }));
        },
        // beforeDestroy() {
        //     // eventBus.$off('MainBlockDelete');
        // }
    }
    // <!-- Google tag (gtag.js) -->
    // window.dataLayer = window.dataLayer || [];
    // function gtag(){dataLayer.push(arguments);}
    // gtag('js', new Date()); gtag('config', 'G-D08V2FNZW0');


</script>
<style lang="css">

    @import "./../assets/styles/main.css";
    @import "./../assets/styles/mainTablet.css";
    @import "./../assets/styles/mainMobile.css";
    @import "./../assets/styles/mainBigMobile.css";

    .v-text-field__input{
        color: aliceblue;

    }
    /*.v-main__wrap{*/
    /*    color: aliceblue !important;*/
    /*}*/

    .v-btn__content{
        color: aliceblue;
    }
    .v-main__wrap{
        text-decoration-color: antiquewhite;
        background: #202020;
        color: aliceblue !important;
    }

    .domainNameOnButton{
      position: absolute;
      width: 144px;
      height: 18px;
      left: 50px;
      top: 297px;

      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;

      color: #FFFFFF;

    }

</style>



<!--<template>-->
<!--&lt;!&ndash;    Here goes a form&ndash;&gt;-->
<!--    <div>-->
<!--        Here goes a form-->
<!--&lt;!&ndash;        <DomainInfo></DomainInfo>&ndash;&gt;-->

<!--    </div>-->



<!--</template>-->

<!--<script>-->
<!--    import TutorialDataService from "../services/TutorialDataService";-->
<!--    import request from '../requests';-->
<!--    import requests from "../requests";-->
<!--    import {eventBus} from "@/main";-->
<!--    import DomainInfo from "./reusable/DomainInfo";-->
<!--    export default {-->

<!--        name: "form",-->
<!--        components: {Form},-->
<!--        data() {-->
<!--            return {-->
<!--                valid: true,-->
<!--                tutorial: {-->
<!--                    id: null,-->
<!--                    title: "",-->
<!--                    description: "",-->
<!--                    content: "",-->
<!--                    published: false-->
<!--                },-->
<!--                radioGroup: 1,-->
<!--                radioOptions: [-->
<!--                    {id: 1, description: 'Flat'},-->
<!--                    {id: 2, description: 'House'}-->
<!--                ],-->
<!--                category: 1,-->
<!--                myRules: {-->
<!--                    required: [value => !!value || "Required."]-->
<!--                },-->
<!--                submitted: false-->
<!--            };-->
<!--        },-->
<!--        props: {-->
<!--            editedIndex: Number,-->
<!--            disableFields: Boolean,-->
<!--        },-->

<!--        methods: {-->


<!--            created() {-->
<!--                this.getFK();-->

<!--                this.$router.replace({name: "join", params: {id: "123"}, query: {q1: "q1"}})-->

<!--            }-->

<!--        }-->
<!--    };-->
<!--</script>-->

<!--<style>-->
<!--    .submit-form {-->
<!--        max-width: 300px;-->
<!--        margin: auto;-->
<!--    }-->
<!--</style>-->
